import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const NavigationElem = styled.div`
  position: fixed;
  left: 200vw;
  top: 70px;
  width: 100vw;
  height: calc(100vh - 70px);
  background: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: left 600ms;

  &.open {
    left: 0;
  }

  a {
    display: block;
    margin-bottom: 20px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    position: relative;
    height: 36px;
    padding: 0;
    top: unset;
    left: unset;
    display: none;
    width: max-content;
    margin-bottom: 100px;

    nav {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    &.open {
      left: unset;
    }

    a {
      margin: 0;
      width: max-content;

      &.active {
        color: black;
        border-bottom: 2px solid ${colors.green};
      }
    }

    &.header {
      display: flex;
      flex-direction: row;
    }
  }
`;

interface NavigationProps {
  navIsOpen: boolean;
  header: boolean;
  content: [{
    title: string;
    uri: string;
  }]
}

const Navigation:React.FC<NavigationProps> = ({ navIsOpen, setNavIsOpen, header, content }) => (
  <NavigationElem
    className={
      navIsOpen && header
        ? "open header"
        : navIsOpen
        ? "open"
        : header
        ? "header"
        : ""
    }
  >
    <nav>
      <Link to={"/"} activeClassName="active">Home</Link>
      {content.filter(item => item.slug !== "__home__").map((item, index) => <Link to={"/" + item.uri} activeClassName="active" key={index}>{item.title}</Link>)}
    </nav>
  </NavigationElem>
);

export default Navigation;
