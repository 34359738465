import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles/variables";

const ToggleDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;
  width: 50px;
  margin-top: 20px;
  position: relative;
  cursor: pointer;

  div {
    width: 50px;
    height: 4px;
    background: ${colors.green};
    position: absolute;
    transition: transform 0.2s ease 0s, top 0.3s ease-in-out 0.3s;

    &:first-of-type {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 13px;
      transition: all 300ms;
    }

    &:nth-of-type(3) {
      top: 26px;
    }
  }

  &.open {
    div {
      transition: top 0.2s ease 0s, transform 0.3s ease-in-out 0.3s;
      &:first-of-type {
        top: 13px;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        top: 13px;
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg);
        top: 13px;
      }
    }
  }
`;

interface NavToggle {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavToggle:React.FC<NavToggle> = ({ navIsOpen, setNavIsOpen }) => (
  <ToggleDiv
    className={navIsOpen ? "open" : ""}
    onClick={() => setNavIsOpen(!navIsOpen)}
  >
    <div />
    <div />
    <div />
  </ToggleDiv>
);

export default NavToggle;
