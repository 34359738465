import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import HeaderInnerDesktop from "./HeaderInnerDeskop";
import NavToggle from "./Navigation/NavToggle";

const HeaderInner = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    display: block;

    img {
      width: 50px;
      margin-top: 10px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;

const HeaderElem = styled.div`
  position: fixed;
  height: 70px;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    height: auto;
  }
`;

interface HeaderProps {
  navIsOpen: boolean;
  setNavIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  content: [{
    title: string;
    uri: string;
  }]
  grosserTitel: string;
}

const Header:React.FC<HeaderProps> = ({ navIsOpen, setNavIsOpen, content, grosserTitel }) => (
  <HeaderElem>
    <HeaderInner>
      <Link to="/">
        <img src="/assets/img/logos/wortmarke.svg" />
      </Link>
      <NavToggle navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
    </HeaderInner>
    <HeaderInnerDesktop content={content} grosserTitel={grosserTitel}/>
  </HeaderElem>
);

export default Header;
