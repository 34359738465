/* eslint-disable max-len */

export const colors = {
  green: "#4aa851",
};

export const maxWidth = 940;

export const breakpoints = {
  tablet: 768,
  desktop: 1280,
  max: 1920,
};

export const imgix = {
  fullImage: {
    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 768
          : window.innerWidth < 1280
          ? 1280
          : window.innerWidth >= 1280
          ? 1920
          : 1920
        : 1920,
  },
  halfImage: {
    fit: "crop",
    crop: "entropy",

    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 384
          : window.innerWidth < 1280
          ? 640
          : window.innerWidth >= 1280
          ? 960
          : 960
        : 960,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 384
          : window.innerWidth < 1280
          ? 640
          : window.innerWidth >= 1280
          ? 960
          : 960
        : 960,
  },
  galleryImage: {
    fit: "crop",
    crop: "entropy",

     w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 384
          : window.innerWidth >= 1280
          ? 468
          : 468
        : 468,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 384
          : window.innerWidth > 768
          ? 468
          : 468
        : 468,
  }
}