import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import Navigation from "./Navigation";

const LargeTitle = styled.h1`
  hyphens: none;
`;

const HeaderElem = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 15px 30px 20px;
  max-width: 1280px;
  margin: 0 auto;

  > div:first-of-type {
    width: calc(100% - 240px);
  }

  > div:first-of-type > div:first-of-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 45px;

    img {
      width: 20px;
    }
  }

  > div:last-of-type {
    width: 220px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: flex;
  }

  @media screen AND (min-width: ${breakpoints.max}px) {
    padding: 25px 0px 60px;
  }
`;

interface HeaderInnerProps {
  content: [
    {
      title: string;
      uri: string;
    }
  ];
  grosserTitel: string;
}

const HeaderInnerDesktop: React.FC<HeaderInnerProps> = ({
  content,
  grosserTitel,
}) => (
  <>
    <HeaderElem>
      <div>
        <div>
          {/**
          <a href="https://www.facebook.com/haenni.gartenbau/" target="_blank">
            <img src="/assets/img/icons/instagram_black.svg" />
          </a>
          <a href="https://www.instagram.com/haenni_gartenbau/" target="_blank">
            <img src="/assets/img/icons/facebook-app-logo_black.svg" />
          </a>
           */}
        </div>
        <div>
          <Navigation header content={content} />
        </div>
        <div>
          <LargeTitle>{grosserTitel}</LargeTitle>
        </div>
      </div>
      <div>
        <Link to="/">
          <img src="/assets/img/logos/logo-text.svg" />
        </Link>
      </div>
    </HeaderElem>
  </>
);

export default HeaderInnerDesktop;
