import React, { useState } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import "normalize.css";
import "../styles/fonts.css";
import "../styles/base.css";

import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const DesktopTitle = styled.div`
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;

const LargeTitle = styled.h1`
  hyphens: none;
  padding-left: 20px;
`;
interface DefaultLayoutProps {
  description: string;
  title: string;
  grosserTitel: string;
}

interface NavQueryProps {
  data: {
    cms: {
      Navigation: [
        {
          title: string;
          uri: string;
          slug: string;
          footerOderHeaderSeite: boolean;
        }
      ];
    };
  };
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  description,
  title,
  grosserTitel,
}) => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  const data = useStaticQuery<NavQueryProps>(graphql`
    query {
      cms {
        Navigation: entries(section: "main", level: 1) {
          title
          uri
          slug
          ... on CMS_main_galerie_Entry {
            footerOderHeaderSeite
          }
          ... on CMS_main_default_Entry {
            footerOderHeaderSeite
          }
        }
      }
    }
  `);

  return (
    <LayoutRoot>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/img/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/img/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/img/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/img/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/img/favicon/safari-pinned-tab.svg"
          color="##5bbad5"
        />
        <link rel="shortcut icon" href="/assets/img/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="##9f00a7" />
        <meta
          name="msapplication-config"
          content="/assets/img/favicon/browserconfig.xml"
        />
        <meta name="theme-color" content="##ffffff" />
        <link href="/lightbox/css/lightbox.css" rel="stylesheet" />
        <script src="/lightbox/js/lightbox-plus-jquery.js" />
        <meta name="description" content={description} />
        <title>{title ? `${title} | ` : ""}Hänni Gartenbau</title>
      </Helmet>
      <Header
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
        content={data.cms.Navigation.filter(
          (item) => !item.footerOderHeaderSeite
        )}
        grosserTitel={grosserTitel}
      />
      <Navigation
        navIsOpen={navIsOpen}
        setNavIsOpen={setNavIsOpen}
        content={data.cms.Navigation.filter(
          (item) => !item.footerOderHeaderSeite
        )}
      />
      <LayoutMain>
        <DesktopTitle>
          <LargeTitle>{grosserTitel}</LargeTitle>
        </DesktopTitle>
        {children}
      </LayoutMain>
      <Footer
        content={data.cms.Navigation.filter(
          (item) => item.footerOderHeaderSeite
        )}
      />
    </LayoutRoot>
  );
};

export default DefaultLayout;
