import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const FooterGrid = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 16px;
  padding-bottom: 45px;

  div:nth-of-type(2) {
    border-top: 1.5px solid ${colors.green};
    overflow: hidden;
    display: flex;
    grid-gap: 10px;
    margin: 20px;
    padding-top: 6px;
    flex-direction: row;
    justify-content: flex-end;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
    margin-top: 24px;

    div:nth-of-type(2) {
      margin: 0 20px 0 0;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-gap: 60px;
    margin: 0 auto;
    margin-top: 32px;
    padding-left: 30px;
    padding-right: 30px;

    div:nth-of-type(2) {
      margin: 0;
      grid-gap: 20px;
    }
  }

  @media screen AND (min-width: ${breakpoints.max}px) {
    padding-left: 0;
    padding-right: 0;
    grid-gap: 90px;
  }
`;

interface FooterProps {
  content: [{
    title: string;
    uri: string;
  }]
}

const Footer = ({content}) => (
  <footer>
    <FooterGrid>
      <div>&nbsp;</div>
      <div>
        {content.map((item, index) => <Link to={"/" + item.uri} key={index}>{item.title}</Link>)}
      </div>
    </FooterGrid>
  </footer>
);

export default Footer;
